import React from "react";
import { Link } from "react-router-dom";
import "../assets/Home.css";

const Home = () => {
  return (
    <>
      
      <div className="nav-div">
        <Link to="/Briefing">
          <h2>Briefing</h2>
        </Link>
        <Link to="/Pre_Deployment">
          <h2>Pre-Deployment</h2>
        </Link>
      </div>
    </>
  );
};

export default Home;
