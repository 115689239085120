import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // NAVIGATION TOOL
import { Helmet } from "react-helmet"; // ALTERNATIVE HEAD SECTION
import "./App.css";
import Pre_Deployment from "./components/Pre_Deployment";
import Briefing from "./components/Briefing";
import Home from "./components/Home";

const App = () => {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <script
            src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.0/jquery.min.js"
            integrity="sha512-3gJwYpMe3QewGELv8k/BX9vcqhryRdzRMxVfq6ngyWXwo03GFEzjsUm8Q7RZcHPHksttq7/GFoxjCVUjkjvPdw=="
            crossorigin="anonymous"
            referrerpolicy="no-referrer"
          ></script>
          <title>FPX Checklists | WEB</title>
        </Helmet>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Pre_Deployment" element={<Pre_Deployment />} />
          <Route exact path="/Briefing" element={<Briefing />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
