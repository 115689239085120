import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../assets/Nav.css";
import chevron from "../images/chevron.png";

const Nav = () => {
  const location = useLocation();
  const isBriefingPage = location.pathname === "/Briefing";
  const isPreDeploymentPage = location.pathname === "/Pre_Deployment";

  return (
    <nav>
      <Link to="/">
        <button>
          <img src={chevron} alt="fpx-chevron" />
        </button>
      </Link>

      <Link to="/Briefing">
        <button
          style={{
            borderBottom: isBriefingPage
              ? "3px solid var(--color1)"
              : "1px solid var(--color3)",
            color: isBriefingPage ? "var(--color1)" : "var(--color3)",
          }}
        >
          Briefing List
        </button>
      </Link>

      <Link to="/Pre_Deployment">
        <button
          style={{
            borderBottom: isPreDeploymentPage
              ? "3px solid var(--color1)"
              : "1px solid var(--color3)",
            color: isPreDeploymentPage ? "var(--color1)" : "var(--color3)",
          }}
        >
          Pre Deployment
        </button>
      </Link>
    </nav>
  );
};

export default Nav;
