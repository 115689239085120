import React, { useState } from "react";
import "../assets/input_fields.css";
import "../assets/pre_style.css";
import Nav from "./Nav";


const Form = () => {
  const [domain, setDomain] = useState("");
  const [autor, setAutor] = useState("");

  const [schrift, setSchrift] = useState("");
  const [schriftFalsch, setSchriftFalsch] = useState("");

  const [links, setLinks] = useState(true);
  const [linksFalsch, setLinksFalsch] = useState("");

  const [responsiv, setResponsiv] = useState(true);
  const [responsivFalsch, setResponsivFalsch] = useState("");

  const [testumgebung, setTestumgebung] = useState(true);
  const [testumgebungFalsch, setTestumgebungFalsch] = useState("");

  const [bildgr, setBildgr] = useState(true);
  const [bildgrFalsch, setBildgrFalsch] = useState("");

  const [formular, setFormular] = useState(true);
  const [formularFalsch, setFormularFalsch] = useState("");

  const [fehlermeldung , setFehlermeldung] = useState(true);
  const [fehlermeldungFalsch, setFehlermeldungFalsch] = useState("");

  const [https , sethttps] = useState(true);
  const [favicon , setFavicon] = useState(true);
  const [impressum , setImpressum] = useState(true);
  const [seo , setSeo] = useState(true);
  const [cookie , setCookie] = useState(true);

  const [sonstiges , setSonstiges] = useState(true);
  const [sonstigesFalsch, setSonstigesFalsch] = useState("");

  const [checked, setChecked] = useState({});


  

  
console.log(schrift);
  const mailtoLink= () => {


    const subject = "Test der Seite " + domain;
    
    if(schrift == false){
      var fehler_schrift = `Schrift: ${schriftFalsch}` + "\n\n";
    } else {
      var fehler_schrift = "";
    };
    if(links == false){
      var fehler_links = `Links: ${linksFalsch}` + "\n\n";
    } else {
      var fehler_links = "";
    };
    if(responsiv == false){
      var fehler_responsiv = `Responsiv: ${responsivFalsch}` + "\n\n";
    } else {
      var fehler_responsiv = "";
    };
    if(testumgebung == false){
      var fehler_testumgebung = `Testumgebung: ${testumgebungFalsch}` + "\n\n";
    } else {
      var fehler_testumgebung = "";
    };
    if(bildgr == false){
      var fehler_bildgr = `Dateigrößen: ${bildgrFalsch}` + "\n\n";
    } else {
      var fehler_bildgr = "";
    };
    if(formular == false){
      var fehler_formular = `Formular: ${formularFalsch}` + "\n\n";
    } else {
      var fehler_formular = "";
    };
    if(fehlermeldung == false){
      var fehler_fehlermeldung = `Fehlermeldung: ${fehlermeldungFalsch}` + "\n\n";
    } else {
      var fehler_fehlermeldung = "";
    };
    if(https == false){
      var fehler_https = "https nicht gesetzt" + "\n\n";
    } else {
      var fehler_https = "";
    };
    if(favicon == false){
      var fehler_favicon = "Kein Favicon" + "\n\n";
    } else {
      var fehler_favicon = "";
    };
    if(impressum == false){
      var fehler_impressum = "Impressum/Datenschutz/AGBs fehlen" + "\n\n";
    } else {
      var fehler_impressum = "";
    };
    if(seo == false){
      var fehler_seo = "SEO Problem" + "\n\n";
    } else {
      var fehler_seo = "";
    };
    if(cookie == false){
      var fehler_cookie = "Keine Cookies gesetzt / Fehler bei der Cookie anzeige" + "\n\n";
    } else {
      var fehler_cookie = "";
    };
    if(sonstiges == false){
      var fehler_sonstiges = `Sonstiges: ${sonstigesFalsch}` + "\n\n";
    } else {
      var fehler_sonstiges = "";
    };

    if(fehler_schrift == "" && fehler_links == "" && fehler_responsiv == "" && fehler_testumgebung == ""
    && fehler_bildgr == "" && fehler_formular == "" && fehler_fehlermeldung == "" && fehler_https == ""
    && fehler_favicon == "" && fehler_impressum == "" && fehler_seo == "" && fehler_cookie == "" && fehler_sonstiges == ""){
      
      var inhalt = "Keine!";
    } else {
      var inhalt = "";
    }

    const body = "Folgende Fehler wurden auf deiner Seite gefunden: " + `${inhalt}` + "\n\n" + `${fehler_schrift}` + 
      `${fehler_links}` + `${fehler_responsiv}` + `${fehler_testumgebung}` + `${fehler_bildgr}` + `${fehler_formular}` + 
      `${fehler_fehlermeldung}` + `${fehler_https}` + `${fehler_favicon}` + `${fehler_impressum}` + `${fehler_seo}` + 
      `${fehler_cookie}` + `${fehler_sonstiges}`;
    

    
  
    const mailto = `mailto:${autor}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
    return mailto;
    
    };
  

    function click() {
      const radio = document.querySelector(".schrift");
      const schrift = document.querySelector(".abschnitt_schrift");

      schrift.classList.add("success");
    }




  return (
    <>
      <Nav />

      <form className="container-briefing">

        <div className="field">
          <input id="domain" 
            name="domain" 
            className="field__input" 
            placeholder="Domain"
            onChange={(event) => {
              setDomain(event.target.value);
            }}
            value={domain}
          /> 
          <span 
            className="field__label-wrap" 
            aria-hidden="true">
              <span className="field__label">
                Welche Webseite wird geprüft?
              </span>
          </span>
        </div>

        <div className="field">
          <select
            name="autor" 
            id="autor" 
            onChange={(event) => {
              setAutor(event.target.value);
            }}
            value={autor}
          >
            
            <option 
              value="" 
              disabled
            > Wer hat die Webseite erstellt?
            </option>
            <option
              value="n.koch@fourplex.de" 
            >
              Erstellt von Nico
            </option>
            <option
              value="d.grassl@fourplex.de" 
            >
              Erstellt von Daniel
            </option>
            <option
              value="k.spengler@fourplex.de" 
            >
              Erstellt von Ksenia
            </option>
            <option
              value="m.chan@fourplex.de" 
            >
              Erstellt von Markus
            </option>
          </select>
        </div>

        <div className="untersuchung">

          <div className="abschnitt abschnitt_schrift">

            <h2>Schrift</h2>
            <p>Keine Tippfehler? Überall richtige Schriftart? Text ragt nirgendwo aus?</p>

            <div className="radio">
              <input type="radio" 
                id="schrift" 
                name="schrift" 
                className="schrift" 
                onClick= {() => {
                  click();
                }}
              />
              <label htmlFor="schrift" className="schrift">
                ja
              </label>
              <input type="radio" 
                id="schriftFalsch" 
                name="schrift" 
                className="schrift" 
                onChange={(event) => {
                  setSchrift(false);
                }}
                value ={schrift}
              />
              <label htmlFor="schriftFalsch">
                nein
              </label>
            </div>

            {schrift ? (<p></p>) : (
              <div className="field">
                <textarea 
                  id="schriftFalsch" 
                  className="field__input" 
                  placeholder="Fehler"
                  onChange={(event) => {
                    setSchriftFalsch(event.target.value);
                  }}
                  value={schriftFalsch}
                >
                </textarea>
                <span 
                  className="field__label-wrap" 
                  aria-hidden="true"
                >
                  <span 
                    className="field__label"
                  >
                    Wo ist der Fehler?
                  </span>
                </span>
              </div>

            )}
          </div>
          
          <div className="abschnitt abschnitt_links">
            <h2>Links</h2>
            <p>Sind alle Links richtig gesetzt?</p>

            <div className='radio'>
                <input type="radio" 
                  id="links" 
                  name="links" 
                  className="links" 
                  onChange={(event) => {
                    setLinks(true); 
                  }}
                  value ={links}
                  
                />
                <label htmlFor="links">
                  ja
                </label>
                <input type="radio" 
                  id="linksFalsch" 
                  name="links" 
                  className="links" 
                  onChange={(event) => {
                    setLinks(false);
                  }}
                  value ={links}
                />
                <label htmlFor="linksFalsch">
                  nein
                </label>
            </div>

            {links ? (<p></p>) : (     
              <div className="field">
                <textarea 
                  id="verlinkung" 
                  className="field__input" 
                  placeholder="Fehler"
                  onChange={(event) => {
                    setLinksFalsch(event.target.value);
                  }}
                  value={linksFalsch}
                  
                ></textarea>
                <span 
                  className="field__label-wrap" 
                  aria-hidden="true"
                >
                  <span className="field__label">
                    Wo ist der Verlinkungsfehler?
                  </span>
                </span>
              </div>
            )}
          </div>
        
          <div className="abschnitt abschnitt_responsiv">
            <h2>Responsiv</h2>
            <p>Funktioniert das Design auf anderen Geräten (Desktop, Tablet, Handy)?</p>

            <div className='radio'>
              <input type="radio" 
                id="responsiv" 
                name="responsiv" 
                className="responsiv" 
                onChange={(event) => {
                  setResponsiv(true); 
                }}
                value ={responsiv}
              />
              <label htmlFor="responsiv">
                ja
              </label>
              <input 
                type="radio" 
                id="responsivFalsch" 
                name="responsiv" 
                className="responsiv" 
                onChange={(event) => {
                  setResponsiv(false);
                }}
                value ={responsiv}
              />
              <label htmlFor="responsivFalsch">
                nein
              </label>
            </div>

            {responsiv ? (<p></p>) : (
              <div className="field">
                <textarea 
                  id="responsiv" 
                  className="field__input" 
                  placeholder="Fehler"
                  onChange={(event) => {
                    setResponsivFalsch(event.target.value);
                  }}
                  value={responsivFalsch}
                >
                </textarea>
                <span 
                  className="field__label-wrap" 
                  aria-hidden="true"
                >
                  <span 
                    className="field__label"
                  >
                    Wo ist der Fehler?
                  </span>
                </span>
              </div>
            )}
          </div>
          
          <div className="abschnitt abschnitt_testumgebung">
            <h2>Testumgebung</h2>
            <p>Funktioniert das Design mit anderen Betriebssystemen (Windows, Android, OSX, iOS, Chrome, Firefox, Safari, Edge)?</p>

            <div className='radio'>
              <input 
                type="radio" 
                id="testumgebung" 
                name="testumgebung" 
                className="testumgebung" 
                onChange={(event) => {
                  setTestumgebung(true); 
                }}
                value ={testumgebung}
              />
              <label htmlFor="testumgebung">
                ja
              </label>
              <input 
                type="radio" 
                id="testumgebungFalsch" 
                name="testumgebung" 
                className="testumgebung"
                onChange={(event) => {
                  setTestumgebung(false);
                }}
                value ={testumgebung}
              />
              <label htmlFor="testumgebungFalsch">
                nein
              </label>
            </div>
            
            {testumgebung ? (<p></p>) : (
              <div className="field">
                <textarea 
                  id="testumgebung" 
                  className="field__input" 
                  placeholder="Fehler"
                  onChange={(event) => {
                    setTestumgebungFalsch(event.target.value);
                  }}
                  value={testumgebungFalsch}
                >
                </textarea>
                <span 
                  className="field__label-wrap" 
                  aria-hidden="true"
                >
                  <span 
                    className="field__label"
                  >
                    Wo ist der Fehler?
                  </span>
                </span>
              </div>
            )}
          </div>        
          
          <div className="abschnitt abschnitt_bildgr">
            <h2>Dateigrößen</h2>
            <p>Sind die Bilddateien auf max 300KB komprimiert?</p>

            <div className='radio'>
              <input type="radio" 
                id="bildgr" 
                name="bildgr" 
                className="bildgr" 
                onChange={(event) => {
                  setBildgr(true); 
                }}
                value ={bildgr}
              />
              <label htmlFor="bildgr">
                ja
              </label>
              <input type="radio" 
                id="bildgrFalsch" 
                name="bildgr" 
                className="bildgr" 
                onChange={(event) => {
                  setBildgr(false);
                }}
                value ={bildgr}
              />
              <label htmlFor="bildgrFalsch">
                nein
              </label>
            </div>

            {bildgr ? (<p></p>) : (
              <div className="field">
                <textarea 
                  id="bildgr" 
                  className="field__input" 
                  placeholder="Fehler"
                  onChange={(event) => {
                    setBildgrFalsch(event.target.value);
                  }}
                  value={bildgrFalsch}
                >
                </textarea>
                <span 
                  className="field__label-wrap" 
                  aria-hidden="true"
                >
                  <span 
                    className="field__label"
                  >
                    Welche Datei ist zu groß?
                  </span>
                </span>
              </div>
            )}
          </div>        
          
          <div className="abschnitt abschnitt_formular">
            <h2>Formular</h2>
            <p>Funktioniert das Formular? Ist die richtige Mail hinterlegt?</p>

            <div className='radio'>
              <input type="radio" 
                id="formular" 
                name="formular" 
                className="formular" 
                onChange={(event) => {
                  setFormular(true); 
                }}
                value ={formular}
              />
              <label htmlFor="formular">
                ja
              </label>
              <input type="radio" 
                id="formularFalsch" 
                name="formular" 
                className="formular" 
                onChange={(event) => {
                  setFormular(false); 
                }}
                value ={formular}
              />
              <label htmlFor="formularFalsch">
                nein
              </label>
            </div>

            {formular ? (<p></p>) : (
              <div className="field">
                <textarea 
                  id="formular" 
                  className="field__input" 
                  placeholder="Fehler"
                  onChange={(event) => {
                    setFormularFalsch(event.target.value);
                  }}
                  value={formularFalsch}
                >
                </textarea>
                <span 
                  className="field__label-wrap" 
                  aria-hidden="true"
                >
                  <span 
                    className="field__label"
                  >
                    Wo ist der Fehler?
                  </span>
                </span>
              </div>
            )}
          </div>        

          <div className="abschnitt abschnitt_fehlermeldung">
            <h2>Fehlermeldungen</h2>
            <p>Sind keine Fehlermeldungen in der Konsole?</p>

            <div className='radio'>
              <input type="radio" 
                id="fehlermeldung" 
                name="fehlermeldung" 
                className="fehlermeldung" 
                onChange={(event) => {
                  setFehlermeldung(true);
                }}
                value ={fehlermeldung}
              />
              <label htmlFor="fehlermeldung">
                ja
              </label>
              <input type="radio" 
                id="fehlermeldungFalsch" 
                name="fehlermeldung" 
                className="fehlermeldung" 
                onChange={(event) => {
                  setFehlermeldung(false);
                }}
                value ={fehlermeldung}
              />
              <label htmlFor="fehlermeldungFalsch">
                nein
              </label>
            </div>

            {fehlermeldung ? (<p></p>) : (
              <div className="field">
                <textarea 
                  id="fehlermeldung" 
                  className="field__input" 
                  placeholder="Fehler"
                  onChange={(event) => {
                    setFehlermeldungFalsch(event.target.value);
                  }}
                  value={fehlermeldungFalsch}
                >
                </textarea>
                <span 
                  className="field__label-wrap" 
                  aria-hidden="true"
                >
                  <span 
                    className="field__label"
                  >
                    Welche Fehlermeldung taucht auf?
                  </span>
                </span>
              </div>
            )}
          </div>        
        
          <div className="abschnitt abschnitt_https">
            <h2>https:// aktivieren und erzwingen</h2>
            <div className='radio'>
              <input type="radio" 
                id="https" 
                name="https" 
                className="https" 
                onChange={(event) => {
                  sethttps(true);
                }}
                value={https}
              />
              <label htmlFor="https">
                ja
              </label>
              <input type="radio" 
                id="httpsFalsch" 
                name="https" 
                className="https" 
                onChange={(event) => {
                  sethttps(false);
                }}
                value={https}
              />
              <label htmlFor="httpsFalsch">
                nein
              </label>
            </div>
          </div>        
          
          <div className="abschnitt abschnitt_favicon">
            <h2>Favicon</h2>
            <div className='radio'>
              <input type="radio" 
                id="favicon" 
                name="favicon" 
                className="favicon" 
                onChange={(event) => {
                  setFavicon(true);
                }}
                value={favicon}
              />
              <label htmlFor="favicon">
                ja
              </label>
              <input type="radio" 
                id="faviconFalsch" 
                name="favicon" 
                className="favicon" 
                onChange={(event) => {
                  setFavicon(false);
                }}
                value={favicon}
              />
              <label htmlFor="faviconFalsch">
                nein
              </label>
            </div>
          </div>      
          
          <div className="abschnitt abschnitt_impressum">
            <h2>Impressum, Datenschutz, AGB</h2>
            <div className='radio'>
              <input type="radio" 
                id="impressum" 
                name="impressum" 
                className="impressum" 
                onChange={(event) => {
                  setImpressum(true);
                }}
                value={impressum}
              />
              <label htmlFor="impressum">
                ja
              </label>
              <input type="radio" 
                id="impressumFalsch" 
                name="impressum" 
                className="impressum" 
                onChange={(event) => {
                  setImpressum(false);
                }}
                value={impressum}
              />
              <label htmlFor="impressumFalsch">
                nein
              </label>
            </div>
          </div>      
          
          <div className="abschnitt abschnitt_seo">
            <h2>SEO</h2>
            <div className='radio'>
              <input type="radio" 
                id="seo" 
                name="seo" 
                className="seo" 
                onChange={(event) => {
                  setSeo(true);
                }}
                value={seo}
              />
              <label htmlFor="seo">
                ja
              </label>
              <input type="radio" 
                id="seoFalsch" 
                name="seo" 
                className="seo" 
                onChange={(event) => {
                  setSeo(false);
                }}
                value={seo}
              />
              <label htmlFor="seoFalsch">
                nein
              </label>
            </div>
          </div>      
          
          <div className="abschnitt abschnitt_coockie">
            <h2>Cookies</h2>
            <div className='radio'>
              <input type="radio" 
                id="cookie" 
                name="cookie" 
                className="cookie" 
                onChange={(event) => {
                  setCookie(true);
                }}
                value={cookie}
              />
              <label htmlFor="cookie">
                ja
              </label>
              <input type="radio" 
                id="cookieFalsch" 
                name="cookie" 
                className="cookie" 
                onChange={(event) => {
                  setCookie(false);
                }}
                value={cookie}
              />
              <label htmlFor="cookieFalsch">
                nein
              </label>
            </div>
          </div>

          <div className="abschnitt abschnitt_sonstiges">
            <h2>Sonstiges</h2>
            <p>Funktioniert sonst alles?</p>

            <div className='radio'>
              <input type="radio" 
                id="sonstiges" 
                name="sonstiges" 
                className="sonstiges" 
                onChange={(event) => {
                  setSonstiges(true);
                }}
                value ={sonstiges}
              />
              <label htmlFor="sonstiges">
                ja
              </label>
              <input type="radio" 
                id="sonstigesFalsch" 
                name="sonstiges" 
                className="sonstiges" 
                onChange={(event) => {
                  setSonstiges(false);
                }}
                value ={sonstiges}
              />
              <label htmlFor="sonstigesFalsch">nein</label>
            </div>

            {sonstiges ? (<p></p>) : (
              <div className="field">
                <textarea 
                  id="sonstiges" 
                  className="field__input" 
                  placeholder="Fehler"
                  onChange={(event) => {
                    setSonstigesFalsch(event.target.value);
                  }}
                  value={sonstigesFalsch}
                >
                </textarea>
                <span 
                  className="field__label-wrap" 
                  aria-hidden="true"
                >
                  <span 
                    className="field__label"
                  >
                    Was ist noch aufgefallen?
                  </span>
                </span>
              </div>
            )}
          </div>      
          
        

        </div>

        <a className="custom-btn btn-15" href={mailtoLink()}>Bewertung senden</a>

      </form>
      
                
      
    </>
  );


};
export default Form;






/*
import React, { useState } from "react";
import "../assets/Pre_Deployment.css";
import Nav from "./Nav";

const Pre_Deployment = () => {
  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [name]: checked,
    }));
  };

  return (
    <>
      <Nav />
      <div className="container-pre-deployment">
        <ul>
          <li>
            <input
              type="checkbox"
              name="anchors"
              id=""
              checked={checkedItems.anchors || false}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="anchors"
              className={checkedItems.anchors ? "done" : ""}
            >
              Alle &lt;a&gt; tags anklicken und Zieladresse überprüfen
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="responsiveness"
              id=""
              checked={checkedItems.responsiveness || false}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="responsiveness"
              className={checkedItems.responsiveness ? "done" : ""}
            >
              Responsiveness &#40;Desktop, Tablet, Handy&#41;
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="images"
              id=""
              checked={checkedItems.images || false}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="images"
              className={checkedItems.images ? "done" : ""}
            >
              Bilddateigröße auf max 300KB komprimieren
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="database"
              id=""
              checked={checkedItems.database || false}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="database"
              className={checkedItems.database ? "done" : ""}
            >
              Klappen alle Datenbankinteraktionen &#40;-&gt;SQL&#41;
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="https"
              id=""
              checked={checkedItems.https || false}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="https" className={checkedItems.https ? "done" : ""}>
              https:// aktivieren und erzwingen
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="seo"
              id=""
              checked={checkedItems.seo || false}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="seo" className={checkedItems.seo ? "done" : ""}>
              <a href="https://www.seobility.net/de/">seobility.net</a> Score
              weitesgehend verbessern
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="console_errors"
              id=""
              checked={checkedItems.console_errors || false}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="console_errors"
              className={checkedItems.console_errors ? "done" : ""}
            >
              Alle Fehlermeldungen in der Konsole bereinigen
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="icons"
              id=""
              checked={checkedItems.icons || false}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="icons" className={checkedItems.icons ? "done" : ""}>
              Favicon &amp; WP-Theme-Thumbnail setzen
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="description"
              id=""
              checked={checkedItems.description || false}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="description"
              className={checkedItems.description ? "done" : ""}
            >
              Meta-Beschreibung hinterlegen
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="client"
              id=""
              checked={checkedItems.client || false}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="client"
              className={checkedItems.client ? "done" : ""}
            >
              Richte einen passenden Kundenzugang ein
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="cookies"
              id=""
              checked={checkedItems.cookies || false}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="cookies"
              className={checkedItems.cookies ? "done" : ""}
            >
              Cookie Abfrage mit allen Verlinkungen vorhanden?
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="inputs"
              id=""
              checked={checkedItems.inputs || false}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="inputs"
              className={checkedItems.inputs ? "done" : ""}
            >
              Lasse kein Feld aus! Setze deine input-fields auf required
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="browser"
              id=""
              checked={checkedItems.browser || false}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="browser"
              className={checkedItems.browser ? "done" : ""}
            >
              Testumgebung ändern &#40;Windows, Android, OSX, iOS, Chrome,
              Firefox, Safari, Edge&#41;
            </label>
          </li>

          <li>
            <input
              type="checkbox"
              name="legal"
              id=""
              checked={checkedItems.legal || false}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="legal" className={checkedItems.legal ? "done" : ""}>
              Impressum, Datenschutz, AGB nicht vergessen
            </label>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Pre_Deployment;
*/