import React, { useState, useEffect } from "react";
import "../assets/Briefing.css";
import Nav from "./Nav";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Briefing = () => {
  const [focusedInput, setFocusedInput] = useState("");
  const [validInputs, setValidInputs] = useState({});

  const handleInputFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValidInputs((prevValidInputs) => ({
      ...prevValidInputs,
      [name]: value.trim() !== "",
    }));
    localStorage.setItem(name, value);
  };

  useEffect(() => {
    const inputNames = [
      "goal",
      "target",
      "functions",
      "design",
      "content",
      "hosting_domain",
      "accessability",
      "social_media",
      "payment_gateways",
      "translations",
      "deadline",
      "budget",
      "contact",
      "info",
    ];

    inputNames.forEach((inputName) => {
      const savedValue = localStorage.getItem(inputName);
      if (savedValue) {
        const inputField = document.querySelector(`[name=${inputName}]`);
        if (inputField) {
          inputField.value = savedValue;
        }
      }
    });
  }, []);

  const clearInputFields = () => {
    const inputFields = document.querySelectorAll(
      ".container-briefing input, .container-briefing textarea"
    );
    inputFields.forEach((inputField) => {
      inputField.value = "";
      localStorage.removeItem(inputField.name);
    });
  };

  // const downloadPDF = () => {
  //   const input = document.querySelector(".container-briefing");
  //   const contentHeight = input.scrollHeight;

  //   html2canvas(input, {
  //     logging: true,
  //     letterRendering: 1,
  //     useCORS: true,
  //     windowHeight: contentHeight,
  //   }).then((canvas) => {
  //     const imgWidth = 210;
  //     const imgHeight = 800;
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF({
  //       orientation: "portrait",
  //       unit: "mm",
  //       dpi: 300,
  //       format: [imgWidth, imgHeight],
  //     });
  //     pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
  //     pdf.save("Briefing-Sheet.pdf");
  //   });
  // };

  const downloadPDF = () => {
    const input = document.querySelector(".container-briefing");
    const contentHeight = input.scrollHeight;
    const contentWidth = input.scrollWidth;
    const file_name = document.querySelector("#project-title").value;

    html2canvas(input, {
      logging: true,
      letterRendering: 1,
      useCORS: true,
      windowHeight: contentHeight,
    }).then((canvas) => {
      const imgWidth = 210;
      const imgHeight = (contentHeight * imgWidth) / contentWidth; // Maintain aspect ratio
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [imgWidth, imgHeight],
      });
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(file_name + "- Briefing_Sheet");
    });
  };

  return (
    <>
      <Nav />
      <input id="project-title" type="text" placeholder="Project Title" />
      <div className="container-briefing">
        <div className="input-box">
          <h4>Projektziel:</h4>
          <label htmlFor="goal">Was ist das Hauptziel?</label>
          <input
            type="text"
            name="goal"
            onFocus={() => handleInputFocus("goal")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "goal" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Zielgruppe:</h4>
          <label htmlFor="target">
            Gibt es eine Zielgruppe? Welche Bedürfnisse & Interessen haben
            diese?
          </label>
          <input
            type="text"
            name="target"
            onFocus={() => handleInputFocus("target")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "target" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Funktionen:</h4>
          <label htmlFor="functions">
            Sollen bestimmte Features implementiert werden?
          </label>
          <input
            type="text"
            name="functions"
            onFocus={() => handleInputFocus("functions")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "functions" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Design:</h4>
          <label htmlFor="design">
            Gibt es eine Vorgabe/ein CD? &#40;Farben, Schriften, Icons,
            Bilderstil&#41;
          </label>
          <input
            type="text"
            name="design"
            onFocus={() => handleInputFocus("design")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "design" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Content:</h4>
          <label htmlFor="content">Wer wird den Content liefern?</label>
          <input
            type="text"
            name="content"
            onFocus={() => handleInputFocus("content")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "content" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Hosting & Domain:</h4>
          <label htmlFor="hosting_domain">
            Existiert eine Domain? Muss sie reserviert werden? Umgezogen?
          </label>
          <input
            type="text"
            name="hosting_domain"
            onFocus={() => handleInputFocus("hosting_domain")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "hosting_domain" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Barrierefreiheit:</h4>
          <label htmlFor="accessability">
            Muss die Seite Barrierefrei werden?
          </label>
          <input
            type="text"
            name="accessability"
            onFocus={() => handleInputFocus("accessability")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "accessability" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Social-Media:</h4>
          <label htmlFor="social_media">
            Sollen gewisse Konten verknüpft werden?
          </label>
          <input
            type="text"
            name="social_media"
            onFocus={() => handleInputFocus("social_media")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "social_media" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Zahlungsmethoden:</h4>
          <label htmlFor="payment_gateways">
            Welche Zahlungsmethoden müssen integriert werden?
          </label>
          <input
            type="text"
            name="payment_gateways"
            onFocus={() => handleInputFocus("payment_gateways")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "payment_gateways" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Übersetzungen:</h4>
          <label htmlFor="translations">
            Müssen mehrere Sprachen supported werden?
          </label>
          <input
            type="text"
            name="translations"
            onFocus={() => handleInputFocus("translations")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "translations" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Deadline:</h4>
          <label htmlFor="deadline">
            Bis wann muss das Projekt/die erste Version stehen?
          </label>
          <input
            type="text"
            name="deadline"
            onFocus={() => handleInputFocus("deadline")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "deadline" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Budget:</h4>
          <label htmlFor="budget">Wie viel Budget steht zur Verfügung?</label>
          <input
            type="text"
            name="budget"
            onFocus={() => handleInputFocus("budget")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "budget" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Ansprechpartner:</h4>
          <label htmlFor="contact">Wer wird mein Ansprechpartner sein?</label>
          <input
            type="text"
            name="contact"
            onFocus={() => handleInputFocus("contact")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "contact" ? "focused" : ""}
          />{" "}
        </div>

        <div className="input-box">
          <h4>Zusätzliche Info:</h4>
          <label htmlFor="info">Wurde etwas zusätzlich besprochen?</label>
          <textarea
            name="info"
            onFocus={() => handleInputFocus("info")}
            onBlur={() => setFocusedInput("")}
            onChange={handleInputChange}
            className={focusedInput === "info" ? "focused" : ""}
          ></textarea>
        </div>
      </div>

      <div className="button-container">
        <button className="download-button" onClick={() => downloadPDF()}>
          Download
        </button>
        <button className="clear-button" onClick={clearInputFields}>
          Clear Fields
        </button>
      </div>
    </>
  );
};

export default Briefing;
